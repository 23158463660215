<script setup>
import { computed, onMounted, ref, toRef } from 'vue';

const props = defineProps({
  modelValue: [String, Number],
  placeholder: String,
  type: {
    type: String,
    default: 'text'
  },
  labelWidth: String,
  fieldStyle: String,
  disabled: Boolean,
  max: [String, Number],
  min: [String, Number],
  step: {
    type: [String, Number],
    default: null
  },
  suffix: String,
  // New error-related props
  error: {
    type: Boolean,
    default: false
  },
  errorMessage: {
    type: String,
    default: ''
  }
});

const emit = defineEmits(['update:modelValue', 'keyup', 'keydown', 'blur']);

const input = ref(null);

const labelWidth = computed(() => {
  const width = props.labelWidth;
  if (width === 'sm') return "w-28";
  if (width === 'xs') return 'w-20';
  return "w-28 md:w-52";
});

const inputPaddingLeft = computed(() => {
  const width = props.labelWidth;
  if (width === 'sm') return "pl-36";
  if (width === 'xs') return 'pl-28';
  return "pl-36 md:pl-48";
});

const borderClass = computed(() => {
  if (props.error) return 'border-red-500 focus:border-red-500 focus:ring-red-500';
  return 'border-gray-200 focus:border-blue-500 focus:ring-blue-500';
});

if (props.type == 'number') {
  if (props.step == 1) {
    emit('update:modelValue', parseInt(props.modelValue));
  } else {
    emit('update:modelValue', parseFloat(props.modelValue).toFixed(2));
  }
}

onMounted(() => {
  if (input.value.hasAttribute('autofocus')) {
    input.value.focus();
  }
});

let style = props.fieldStyle;
</script>

<template>
  <div :class="`relative ${props.disabled && 'opacity-50 cursor-not-allowed'}`">
    <label 
      :class="`
        ${labelWidth} 
        ${props.disabled && 'opacity-50 cursor-not-allowed'} 
        absolute text-xs ml-3 mt-1.5 uppercase t-1 font-bold 
        ${error ? 'text-red-500' : 'text-slate-400'} 
        truncate pr-2
      `"
    >
      {{ placeholder }}
    </label>
    
    <label 
      v-if="props.suffix"
      class="absolute opacity-50 font-bold right-0 top-1/2 transform -translate-y-1/2 mr-3"
    >
      {{ props.suffix }}
    </label>
    
    <input
      @blur="e => emit('blur', e)"
      @keydown="e => emit('keydown', e)"
      @keyup="e => emit('keyup', e)"
      :disabled="props.disabled"
      :max="props.max"
      :min="props.min"
      :step="step || 1"
      :class="`
        ${borderClass}
        bg-white pt-5 pl-3 pb-2 rounded-md shadow-sm w-full
        focus:outline-none focus:ring-2 focus:ring-opacity-50
        transition duration-150 ease-in-out
        ${props.disabled && 'cursor-not-allowed'}
      `"
      :type="type"
      :value="modelValue"
      @input="$emit('update:modelValue', $event.target.value)"
      ref="input"
      :aria-invalid="error"
      :aria-errormessage="error ? `error-${placeholder}` : undefined"
    />
    
    <!-- Error Message -->
    <div 
      v-if="error && errorMessage"
      :id="`error-${placeholder}`"
      class="mt-1 text-sm text-red-500"
    >
      {{ errorMessage }}
    </div>
  </div>
</template>